import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { PanZoomConfig, PanZoomAPI } from 'ngx-panzoom';
import { NzNotificationDataOptions } from "ng-zorro-antd/notification";
import { SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "panzoom-image-preview",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class PanZoomImagePreview extends BaseComponent {
  public panZoomConfig: PanZoomConfig = new PanZoomConfig({
    // scalePerZoomLevel: 1
    freeMouseWheelFactor: 0.01
  });
  @Input() selectedImage: File | Blob;
  @Input() fileUrl;
  @Input() textractData: any;
  @Input() shouldShowBoudingBox: boolean = false;
  @Input() typeOfBoundingBox: 'word'|'block';
 
  private panZoomAPI: PanZoomAPI;
  private observerResize: ResizeObserver;
  private isPanZoomReady: boolean = false;
  public viewImageFullScreen: boolean = false;
  public isAnalyzing: boolean = false;
  public imageUrl: any = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.panZoomConfig.api.subscribe((api: PanZoomAPI) => {
      this.panZoomAPI = api;
      this.isPanZoomReady = true;
    }))
  }

  ngOnChanges() {
    this.imageUrl = this.fileUrl;
  }

  ngAfterViewInit(): void {
    this.initResizeSensor()
  }

  initResizeSensor() {
    this.observerResize = new ResizeObserver(() => this.resetPanZoom())
    this.observerResize.observe(this.getContentDocument())
    this.observerResize.observe(document.querySelector(".image-preview"))
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyResizeSensor();
  }
  destroyResizeSensor() {
    this.observerResize?.unobserve(this.getContentDocument())
  }

  private getContentDocument() {
    return document.querySelector(".pod-panzoom .pan-zoom-frame .container-content");
  }

  resetPanZoom() {
    //check if panZoomAPI is available
    if (!this.isPanZoomReady) return;
    const contentWidth = this.getContentDocument()?.clientWidth;
    const contentHeight = this.getContentDocument()?.clientHeight;

    if (!contentWidth || !contentHeight) return;
    // this.panZoomAPI.detectContentDimensions()
    this.panZoomAPI.zoomToFit({ x: 0, y: 0, width: contentWidth, height: contentHeight });
    this.panZoomAPI?.resetView();
    this.panZoomAPI.centerX();
  }

  getExtractedData() {
    if (this.shouldShowBoudingBox) {
      return this.textractData;
    }
    return [];
  }


  async onClickAction(action) {
    switch (action) {
      case 'fullscreen':
        this.viewFullScreen()
        break;
      case 'analyzePhoto':
        this.shouldShowBoudingBox = !this.shouldShowBoudingBox;
        break;
    }
  }

  //actions
  private viewFullScreen() {
    this.viewImageFullScreen = !this.viewImageFullScreen;
    setTimeout(() => {
    this.resetPanZoom()
    }, 10);
  }

  getNotificationOptions(): NzNotificationDataOptions {
    let options: NzNotificationDataOptions = {
      nzPlacement: 'bottomLeft',
      nzDuration: 1500,
      nzStyle: {
        padding: '12px'
      }
    }
    return options;
  }
}
