<div class="container-box" [ngClass]="{fullscreen: viewImageFullScreen}">
  <pan-zoom [config]="panZoomConfig" class="pod-panzoom {{!selectedImage? 'display-none': ''}}">
    <div class="container-content">
      <text-detection class="attached-file" *ngIf="selectedImage" [imgUrl]="imageUrl"
        [model]="getExtractedData()" [typeOfBoundingBox]="typeOfBoundingBox"></text-detection>
    </div>
  </pan-zoom>
  <!-- actions -->
  <div class="actions" *ngIf="selectedImage">
    <button nz-button class="item" (click)="onClickAction('fullscreen')" nz-tooltip nzTooltipTitle="View Fullscreen"
      nzTooltipPlacement="left">
      <span nz-icon [nzType]="viewImageFullScreen? 'shrink':'arrows-alt'" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('analyzePhoto')" nz-tooltip nzTooltipTitle="Analyze Photo"
      nzTooltipPlacement="left" [nzLoading]="isAnalyzing">
      <span nz-icon nzType="monitor" nzTheme="outline"></span>
    </button>
  </div>

  <div class="loading-detection-data" *ngIf="isAnalyzing">
    analyzing photos....
  </div>

  <div class="no-data-box" *ngIf="!selectedImage">
    <img class="no-data" src="/assets/img/nodata.svg" alt="" />
    <div class="">Please upload file first.</div>
  </div>
</div>